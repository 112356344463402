import React from "react"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {size, spacing, color, ensembles, composers } from "../constants"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Person = (props) => (
  <div css={css`
    text-align: center;
    flex: 1 0 150px;
    margin: 20px 20px 0 20px;
    img {
      height: 190px;
      object-fit: cover;
      object-position: 0 10%;
    }
  `}>
    {props.children}
    <p css={css`margin-bottom: 0;`}>{props.name}</p>
  </div>
)


const VereinPage = () => {
  const intl = useIntl()
  return (
    <Layout title={intl.formatMessage({ id: "verein" })}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "verein" })}
      />
        <h2 css={css`font-size: 2em;`}><FormattedMessage id="vorstand" /></h2>
        <div css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          background-color: ${color.COLOR2};
          padding: 10px;
          margin-bottom: 4em;
        `}>
          <Person name={intl.formatMessage({ id: "heinrich" })}>
            <StaticImage
              src="../images/vorstand/hempel-heinrich.jpeg"
              quality={95}
              placeholder={"tracedSVG"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Vorstandsmitglied"
            />
          </Person>
          <Person name="Cindy Winzenried">
            <StaticImage
              src="../images/vorstand/cindy-winzenried.png"
              quality={95}
              placeholder={"tracedSVG"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Vorstandsmitglied"
            />
          </Person>
          <Person name="Erika Bucher">
            <StaticImage
              src="../images/vorstand/erika.jpg"
              quality={95}
              placeholder={"tracedSVG"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Vorstandsmitglied"
            />
          </Person>
          <Person name="Urs Boner">
            <StaticImage
              src="../images/vorstand/urs.jpg"
              quality={95}
              placeholder={"tracedSVG"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Vorstandsmitglied"
            />
          </Person>
          <Person name="Benjamin Engeli">
            <StaticImage
              src="../images/vorstand/benjamin.jpg"
              quality={95}
              placeholder={"tracedSVG"}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Vorstandsmitglied"
            />
          </Person>
        </div>

        <h2 css={css`font-size: 2em;`}><FormattedMessage id="mitglied-werden" /></h2>
        <p>
          <FormattedMessage id="mitglied-werden-text"
          values={{
            a1: msg => (
              <Link to="/kontakt">
                {msg}
              </Link>
            ),
            b: msg => (
              <b>{msg}</b>
            ),
            br: <><br/><br/></>
          }} />
        </p>
    </Layout>
  )
}

export default VereinPage